// import React from 'react';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useStore } from '../../../../hook-store/store';

import { appInstallHandler } from '../../../../utils/ui/ui-util';
import { logoutHandler } from '../../../../utils/user/user';

import { loginPageLink, signupPageLink, kuraStorageLink } from '../../../../App';

import { marks } from '../../../../utils/marks';

import './ToggleNavItems.css';


const ToggleNavItems = props => {
  // console.log('ToggleNavItems-props', props);

  const [t] = useTranslation('translation');
// {t('termsOfUse.text1', 'Terms of use')}

  const [store, dispatch] = useStore();
  const { 
    isAuth, 
    deferredPrompt, 
  }  = store.uiStore;


  const navItems = [
    // { 
    //   id: 'bucket-object', 
    //   // text: 'feed',
    //   text: `${t('nav.01', 'Files')}`, 
    //   link: '/', 
    //   auth: true
    // },
    { 
      id: 'about-page', 
      // text: 'feed',
      text: `About Text Audio Tools`, 
      link: '/about', 
      auth: false
    },
    { 
      id: 'audio-transcriber', 
      // text: 'feed',
      text: `Audio Transcriber`, 
      link: '/audio-transcriber', 
      auth: false
    },
    { 
      id: 'voice-generator', 
      // text: 'feed',
      text: `Voice Gnerator (EN)`, 
      link: '/voice-generator', 
      auth: false
    },
    { 
      id: 'voice-generator-noen', 
      // text: 'feed',
      text: `Voice Gnerator`, 
      link: '/voice-generator-noen', 
      auth: false
    },
    { 
      id: 'answer-finder', 
      // text: 'feed',
      text: `Answer Finder`, 
      link: '/answer-finder', 
      auth: false
    },
    { 
      id: 'userinfo', 
      // text: 'feed',
      text: `${t('nav.02', 'User Info')}`, 
      link: '/userinfo', 
      auth: true
    },
    { 
      id: 'kura-storage', 
      // text: 'feed',
      text: `Kura-Storage`, 
      link: '/', 
      auth: false
    },
    { 
      id: 'login', 
      // text: 'feed',
      text: `${t('nav.03', 'Login')}`, 
      link: '/feed/posts', 
      auth: false
    },
    { 
      id: 'signup', 
      // text: 'feed',
      text: `${t('nav.04', 'Signup')}`, 
      link: '/feed/posts', 
      auth: false
    },
    { 
      id: 'install-app', 
      // text: 'feed',
      text: `${t('nav.05', 'Install App')}`, 
      link: '/', 
      auth: false
    },
    { 
      id: 'logout', 
      // text: 'feed',
      text: `${t('nav.06', 'Logout')}`, 
      link: '/feed/posts', 
      auth: true
    },
  ];

  const logoutConfirm = () => {
    if (window.confirm(`${t('nav.07', "Is it no problem to logout?")}`)) {
     
      // console.log('confirm true');
      // props.onLogout();

      dispatch('SET_ISAUTH', false);
      logoutHandler(); 
    }
  }

  let toggleNavItemsBody;

  toggleNavItemsBody = (
    <div>
      <div>
        {localStorage.getItem('email')}
      </div>
      <ul>
        {navItems.map(item => {
          if (!isAuth && item.id === 'login') {
            return (
              <div className="toggleNavItem">
                <span>
                  <a href={loginPageLink} >
                    <span className='toggleNavItemIcon'>
                      {marks.signInMrak}
                    </span> 
                    <span className='toggleNavItemText'>
                      {item.text}
                    </span>
                  </a>
                </span>
              </div>
            );
          }

          if (!isAuth && item.id === 'signup') {
            return (
              <div className="toggleNavItem">
                <span>
                  <a href={signupPageLink} >
                  <span className='toggleNavItemIcon'>
                    {marks.userPlusMrak}
                  </span> 
                  <span className='toggleNavItemText'>
                    {item.text}
                  </span>
                  </a>
                </span>
              </div>
            );
          }

          // if (isAuth && item.id === 'bucket-object') {
          //   return (
          //       <div className="toggleNavItem">
          //         <Link to={item.link} 
          //           onClick={() => {
          //             dispatch('SET_SHOWTOGGLENAV', false);
          //             // dispatch('SET_DISPLAYTABLE', '');
          //           }}
          //         >
          //           <span className='toggleNavItemIcon'>
          //             {/* {marks.listUlMark} */}
          //             {marks.tableMark} 
          //           </span> 
          //           <span className='toggleNavItemText'>
          //             {item.text}
          //           </span>
          //         </Link>
          //       </div>
          //   );
          // }

          if (item.id === 'about-page') {
            return (
                <div className="toggleNavItem">
                  <Link to={item.link} 
                    onClick={() => {
                      dispatch('SET_SHOWTOGGLENAV', false);
                    }}
                  >
                    <span className='toggleNavItemIcon'>
                      {marks.infoCircleMark} 
                    </span> 
                    <span className='toggleNavItemText'>
                      {item.text}
                    </span>
                  </Link>
                </div>
            );
          }

          if (item.id === 'audio-transcriber') {
            return (
                <div className="toggleNavItem">
                  <Link to={item.link} 
                    onClick={() => {
                      dispatch('SET_SHOWTOGGLENAV', false);
                    }}
                  >
                    <span className='toggleNavItemIcon'>
                      {marks.editMark} 
                    </span> 
                    <span className='toggleNavItemText'>
                      {item.text}
                    </span>
                  </Link>
                </div>
            );
          }

          if (item.id === 'voice-generator') {
            return (
                <div className="toggleNavItem">
                  <Link to={item.link} 
                    onClick={() => {
                      dispatch('SET_SHOWTOGGLENAV', false);
                    }}
                  >
                    <span className='toggleNavItemIcon'>
                      {marks.volumeUpMark} 
                    </span> 
                    <span className='toggleNavItemText'>
                      {item.text}
                    </span>
                  </Link>
                </div>
            );
          }

          if (item.id === 'voice-generator-noen') {
            return (
                <div className="toggleNavItem">
                  <Link to={item.link} 
                    onClick={() => {
                      dispatch('SET_SHOWTOGGLENAV', false);
                    }}
                  >
                    <span className='toggleNavItemIcon'>
                      {marks.volumeUpMark} 
                    </span> 
                    <span className='toggleNavItemText'>
                      {item.text}
                    </span>
                  </Link>
                </div>
            );
          }

          if (item.id === 'answer-finder') {
            return (
                <div className="toggleNavItem">
                  <Link to={item.link} 
                    onClick={() => {
                      dispatch('SET_SHOWTOGGLENAV', false);
                    }}
                  >
                    <span className='toggleNavItemIcon'>
                      {marks.fileTextOMark} 
                    </span> 
                    <span className='toggleNavItemText'>
                      {item.text}
                    </span>
                  </Link>
                </div>
            );
          }


          if (isAuth && item.id === 'userinfo') {
            return (
                <div className="toggleNavItem">
                  <Link to={item.link} 
                    onClick={() => {
                      dispatch('SET_SHOWTOGGLENAV', false);
                    }}
                  >
                    <span className='toggleNavItemIcon'>
                      {marks.userMrak} 
                    </span> 
                    <span className='toggleNavItemText'>
                      {item.text}
                    </span>
                  </Link>
                </div>
            );
          }

          if (item.id === 'kura-storage') {
            return (
              <div className="toggleNavItem">
                <span>
                  <a href={kuraStorageLink}
                    target="_blank" rel="noopener noreferrer"
                  >
                    <span className='toggleNavItemIcon'>
                      {marks.cloudUploadMark}
                    </span> 
                    <span className='toggleNavItemText'>
                      {item.text} {marks.externalLinkMark}
                    </span>
                  </a>
                </span>
              </div>
            );
          }

          if (deferredPrompt && item.id === 'install-app') {
            return (
              <div className="toggleNavItem">
                <Link to={item.link} 
                  onClick={() => {
                    appInstallHandler(deferredPrompt);
                    // dispatch('SET_SHOWTOGGLENAV', false);
                  }}
                >
                  <span className='toggleNavItemIcon'>
                    {marks.plusSquareOMark} 
                  </span> 
                  <span className='toggleNavItemText'>
                    {item.text}
                  </span>
                </Link>
              </div>
            );          
          }
          if (isAuth && item.id === 'logout') {
            return (
                <div className="toggleNavItem">
                  <span
                    onClick={() => { logoutConfirm(); }} 
                  >
                  <span className='toggleNavItemIcon'>
                    {marks.signOutMrak} 
                  </span> 
                  <span className='toggleNavItemText'>
                    {item.text}
                  </span>
                  </span>
                </div>
            );
          }

          else {
            return null;
          }

          // return (
          //   <div  className="toggleNavItem">
          //     <span>
          //     <span className='toggleNavItemIcon'>
          //       {item.text}
          //       {/* {marks.userMrak}  */}
          //       </span> 
          //     </span>
          //   </div>
          // );
        })}
      </ul>
    </div>
  );

  return (
  <Fragment>
    {toggleNavItemsBody}
  </Fragment>
  );

  }
export default ToggleNavItems;
