import { useState, useMemo, Fragment } from 'react'
import { Link } from 'react-router-dom';
import Loader from '../../Loader/Loader';

// import VoiceFileControl from '../FileControl/VoiceFileControl';
import VoiceFileControlNoEn from './VoiceFileControlNoEn';

import { useStore } from '../../../hook-store/store';

// import { TEXTAUDIOTOOLSNODE_URL } from '../../../App';
import '../VoiceGenerator2.css';


function VoiceGenerator2NoEnOutput(props) {

  const { 
    generatedAudioFiles,
    textInput,
   } = props;

  const [store, dispatch] = useStore();

  const [outputList, setOutputList] = useState();


  const createOutputList = () => {
    setOutputList(
      <ul>
        {generatedAudioFiles.slice(0,1).map((ele, index) => {
          console.log(ele)
          return (
            <li key={ele.time}
              className='voiceGenerator2OutputCard'
            >
              <audio className='voiceGenerator2Audio'
                src={URL.createObjectURL(ele.file)}
                controls 
              />
              <div>
                {ele.text.length > 100 && (
                  <span>{ele.text.substring(0, 100)}....</span>
                )}
                {ele.text.length <= 100 && (
                  <span>{ele.text}</span>
                )}
              </div>
              <div>
                Model: {ele.modelDisplayName}, Speed: {ele.speed}, Speaker ID: {ele.sid}, 
              </div>
              {ele.time > 0 && (
                <div>
                  {new Date(ele.time).toLocaleString()}
                </div>
              )}
            </li>
        )
      })}
      </ul>
    )
  }

  const expensiveValue = useMemo(() => {
    console.log('Computing expensive value...');
    if (generatedAudioFiles.length > 0) {
      createOutputList();
    }
  }, [generatedAudioFiles]);

  // let outputBody;

  // if (generatedAudioFiles.length > 0) {

  //   outputBody = (
  //     <ul>
  //       {generatedAudioFiles.slice(0,1).map((ele, index) => {
  //         console.log(ele)
  //         return (
  //           <li key={ele.time}
  //             className='voiceGenerator2OutputCard'
  //           >
  //             <audio className='voiceGenerator2Audio'
  //               src={URL.createObjectURL(ele.file)}
  //               controls 
  //             />
  //             <div>
  //               {ele.text.length > 100 && (
  //                 <span>{ele.text.substring(0, 100)}....</span>
  //               )}
  //               {ele.text.length <= 100 && (
  //                 <span>{ele.text}</span>
  //               )}
  //             </div>
  //             <div>
  //               Model: {ele.modelDisplayName}, Speed: {ele.speed}, Speaker ID: {ele.sid}, 
  //             </div>
  //             {ele.time > 0 && (
  //               <div>
  //                 {new Date(ele.time).toLocaleString()}
  //               </div>
  //             )}
  //           </li>
  //       )
  //     })}
  //     </ul>
  //   )
  // }

  return (
    <Fragment>
      {/* <div>{outputBody}</div> */}
      <div>{outputList}</div>

      {generatedAudioFiles.length > 0 && (
          <VoiceFileControlNoEn 
            generatedAudioFiles={generatedAudioFiles}
            textForOutput={textInput}
            // textForOutput={displayList[displayList.length - 1].text}
          />
        )}
    </Fragment>
  )
}

export default VoiceGenerator2NoEnOutput;
