export const generateTextAudio = async (
  url,
  token,
  language,
  repoId,
  text,
  sid,
  speed
) => {
  try {
    const result = await fetch(`${url}/text-to-speech/generate-text-audio`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        language: language,
        repo_id: repoId,
        // repo_id: 'csukuangfj/vits-piper-en_US-glados|1 speaker',
        text: text,
        sid: sid,
        speed: speed,
      }),
    });

    // const resData = await result.json();
    const resData = await result.blob();

    console.log(result, resData);

    const file = new File(
      [resData], 
      `audio-voice-output-${Date.now()}.wav`,
      { type: 'audio/wav' },
    );
    console.log(file);

    if (result.ok && file) {
      // dispatch('SET_RESERVEDPREFIXES', resData.data);
      return {
        language: language,
        repo_id: repoId,
        text: text,
        sid: sid,
        speed: speed,
        file: file,
        time: Date.now(),
      };
    } else {
      throw new Error("error occured");
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getTextToSpeechModels = async (url, token) => {
  try {
    const result = await fetch(`${url}/text-to-speech/get-models`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      // body: JSON.stringify({})
    });
    // const resData = await result.json();
    const resData = await result.json();

    console.log(result, resData);

    if (result.ok) {
      // dispatch('SET_RESERVEDPREFIXES', resData.data);
      return resData;
    } else {
      throw new Error("error occured");
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};


export const getTextToSpeechApi = async (url, token) => {
  try {
    const result = await fetch(`${url}/text-to-speech/get-api`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      // body: JSON.stringify({})
    });
    // const resData = await result.json();
    const resData = await result.json();

    console.log(result, resData);

    if (result.ok) {
      // dispatch('SET_RESERVEDPREFIXES', resData.data);
      return resData;
    } else {
      throw new Error("error occured");
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const createTextFileFromString = (text, fileName) => {
  try {
    var blob = new Blob([text], {type: 'text/plain'});

    const file = new File(
      [blob], 
      fileName, 
      { type: 'text/plain' }
    );

    console.log(file);
    // setCurrentInputTextFile(file);
    return file;
  } catch(err) {
    console.log(err);
  }
}