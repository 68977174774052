import { Fragment, useState, useEffect } from "react";
import download from 'downloadjs';

import Loader from "../../Loader/Loader";
import VoiceFileUpload from "../FileControl/VoiceFileUpload";

import { useStore } from "../../../hook-store/store";
import { createTextFileFromString } from "../../../utils/voice-generator2-utils/voice-generator2-utils";

import './VoiceFileControlNoEn.css';
import { marks } from "../../../utils/marks";


// `input-for-voice-${Date.now()}` + '.txt'

export default function VoiceFileControlNoEn(props) {
  const { 
    // text,
    generatedAudioFiles,
    textForOutput,
  } = props;
  // console.log('VoiceFileControl-props', props);

  const [store, dispatch] = useStore();
  // console.log('FileControl.jsx-store', store);
  // const { 
  //   outputBlobUrl,
  //  } = store.mlVoiceStore;

  const [currentAudioFile, setCurrentAudioFile] = useState();
  const [currentInputTextFile, setCurrentInputTextFile] = useState();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [selectedType, setSelectedType] = useState('');
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (generatedAudioFiles.length > 0) {
      setCurrentAudioFile(generatedAudioFiles[0].file);
      
      const textFile = createTextFileFromString(
        generatedAudioFiles[0].text,
        `input-for-voice-${Date.now()}` + '.txt',
      );
      setCurrentInputTextFile(textFile);
    }

  },[generatedAudioFiles]);
  // useEffect(() => {
  //   if (outputBlobUrl) {
  //     createFileFromAudio(outputBlobUrl);
  //   }

  //   if (textForOutput) {
  //     createTextFileFromString(textForOutput);
  //   }
  // },[outputBlobUrl]);

  // async function createFileFromAudio(blobUrl) {
  //   try {
  //     setIsLoading(true);

  //     const result = await fetch(blobUrl);
  //     const resData = await result.blob();

  //     // const file = new File(
  //     //   [resData], 'audio-file.' + audioFile.mimeType.split('/')[1], 
  //     //   { type: audioFile.mimeType }
  //     // );

  //     const file = new File(
  //       [resData], 
  //       `audio-voice-output-${Date.now()}` + '.wav', 
  //       { type: 'audio/wav' }
  //     );

      

  //     setCurrentAudioFile(file);
  //     setIsLoading(false);
  //     // dispatch('SET_SPEECHAUDIOFILE', file);

  //     console.log('audiodata file', file);
  //   } catch(err) {
  //     console.log(err);
  //     setIsLoading(false);
  //   }
  // }



  const fileDownloadHandler = (file) => {
    download(
      file,
      file.name,
      file.type,
    );
  };

  return (
  <Fragment>
    <div className="voiceFileControl">
      {isLoading && (
        <Loader />
      )}
      <div className="voiceFileControlButtons">
        {currentAudioFile && (
          <button className="btnBase"
            disabled={isLoading}
            onClick={() => {fileDownloadHandler(currentAudioFile) }}
          >
            {marks.fileAudioOMark} Download audio file {marks.downloadMark}
          </button>
        )}
        <button className="btnBase"
            disabled={isLoading}
            onClick={() => { 
              setShowUploadModal(true);
              setSelectedType('audio');
            }}
          >
           {marks.fileAudioOMark} Upload audio file {marks.uploadMark}
        </button>
        {currentInputTextFile && (
          <button className="btnBase"
            disabled={isLoading}
            onClick={() => { 
              fileDownloadHandler(currentInputTextFile); 
            }}
          >
            {marks.fileTextOMark} Download text file {marks.downloadMark}
          </button>
        )}
        <button className="btnBase"
          disabled={isLoading}
          onClick={() => { 
            setShowUploadModal(true);
            setSelectedType('text');
          }}
        >
          {marks.fileTextOMark} Upload text file {marks.uploadMark}
        </button>
        {showUploadModal && (
          <VoiceFileUpload 
            currentAudioFile={currentAudioFile}
            currentInputTextFile={currentInputTextFile}
            setShowUploadModal={setShowUploadModal}
            selectedType={selectedType}
          />
        )}
      </div>
    </div>
  </Fragment>
  );
}