import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";

import AboutAuth from "../../components/UI/AboutAuth";

import { useStore } from "../../hook-store/store";

import AboutPageImage1 from "../../images/audio-tools-image-crop-h200.jpg";
import AboutPageImage2 from "../../images/cloud-sync-crop-h200.png";

import './AboutPage.css';

const AboutPage = (props) => {

  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();
  const { isAuth } = store.uiStore;

  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);

  return (
    <Fragment>
      <div>
        <div className="aboutPageTitle">
          Kura Text Audio Tools
        </div>

        <div className="aboutPageTopImages">
          <div>
            <img className="aboutPageTopImage"
              src={AboutPageImage1}
            />
          </div>
          <div>
            <img className="aboutPageTopImage"
              src={AboutPageImage2}
            />
          </div>
        </div>
        <p>
          Kura Text Audio Tools
          {' '}
          {t('aboutPage.16', 'provide tools for handling text and audio data.')}
        </p>
        <p>
        {t('aboutPage.17', 'The tools inclede')}
          {' '}
          Audio Transcriber, Voice Generator, Answer Finder.
        </p>
        <br/>

        <p>
          In Audio transcriber, 
          {' '}
          {t('aboutPage.18', 'by using advanced AI-technologies, user can generate text transcript from audio sources such as audio file, video file, and recorded voices.')}
        </p>
        <p>
          {t('aboutPage.19', 'It is possible to choose a language for generating text from more than 90 different languages.')}
        </p>
        {/* <p>
          If users have account in Kura Storage, generated text and audio data can be stored for later use at Kura Storage. These files can be accessed from both desktop and mobile devices.
          It is possible to create account from this page by signup.
        </p> */}
        <br/>
        <p>
          In Voice generator, 
          {' '}
          {t('aboutPage.20', 'user can generate audio from text input.')} 
        </p>
        <p>
        {t('aboutPage.21', 'User can control output audio speed and select voices from more than 900 different voices.')}
        {' '}
        {/* {t('aboutPage.25', 'Supported language is English.')} */}
        </p>
        <br/>

        <p>
          In Answer Finder, 
          {' '}
          {t('aboutPage.22', 'User can find an answer in text context by asking question.')}
          {' '}
          {t('aboutPage.25', 'Supported language is English.')}
        </p>
        <br/>

        <p>
        {t('aboutPage.23', 'Generated audio and text data can be stored')} 
          {' '}
          at Kura Storage. 
          {' '}
          {t('aboutPage.24', 'These files can be accessed from both desktop and mobile devices for later usage.')}
        </p>

        <br/>
        {!isAuth && (
          <AboutAuth />
        )}
      </div>
    </Fragment>
  );
};
export default AboutPage;
